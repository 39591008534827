@import '/apps/wt/webtools/sparta_build_server/tmp/EE81542B/artifacts/components/utilities/global/sparta-style-utility/4.9.4/scss/_sparta-project.scss';
$moduleNameSpace: dynamic-name-space;



$font-stack: Arial, Helvetica, sans-serif;
$primary-color: #333;

[data-sparta-container] .ah-helix-widget-loader-module {
  font: 100% $font-stack;
  color: $primary-color;

  p {
    color: $primary-color;
  }
}
